.faq-page-container {
    min-height: 100vh;
    background-color: white;
    padding: 50px 40px;
    box-sizing: border-box;

    & h1 {
        color: var(--main-accent-blue)
    }
}

.faq-items-container {
    margin-top: 50px;
}

.faq-item {
    border-bottom: 1px solid #ccc;
    margin: 20px 0px;
    padding: 0px 60px;

    & div:first-of-type {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & h3 {
        font-size: 20px;
    }

    & p {
        line-height: 0px;
        color: var(--transparent);
        cursor: default;
        transition: color 0.2s, line-height 0.2s
    }

    & a {
        position: absolute;
        line-height: 0px;
        color: var(--transparent);
        cursor: pointer;
        transition: color 0.2s, line-height 0.2s;
    }
}

.faq-expand-chevron {
    color: #777;
    transition: color 0.2s;
}

.faq-expand-chevron:hover {
    color: var(--main-accent-blue-hi)
}

@media only screen and (max-width: 855px) {
    .faq-item {
        padding: 0px 0px;

        & div:first-of-type {
            gap: 20px
        }
    }
}