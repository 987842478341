.user-posts-container {
    background-color: white;
    min-height: 100vh;
    padding: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;

    > div:first-of-type {
        align-self: flex-start;
        width: 100%;

        > h3 {
            color: var(--main-accent-blue)
        }
    }

    > div:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        & > i {
            align-self: flex-end;
        }
    }
}

.user-post-btn-grp {
    display: flex;
    align-items:flex-end;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 20px;
    margin: 20px 0 2px 0;
    height: 35px;
    width: 100%;

    & button {
        background-color: rgba(0, 0, 0, 0);
        color: #777;
        border: none;
        border-bottom: 0 solid rgba(0, 0, 0, 0);
        font-size: 18px;
        padding: 0px;
        cursor: pointer;
        white-space: nowrap;
        width: fit-content;
        transition: padding-bottom 0.2s, border-bottom 0.2s, color 0.2s;
    }

    .user-post-delete-btn:hover {
        border-bottom: 1px solid var(--warning-delete-hi);
        color: var(--warning-delete-hi);
        padding-bottom: 5px;
    }
    .user-post-edit-btn:hover {
        border-bottom: 1px solid var(--main-accent-blue);
        color: var(--main-accent-blue);
        padding-bottom: 5px;
    }
}

@media only screen and (max-width: 855px) {
    .user-posts-container {
        padding: 40px 0;

        & > div:first-of-type {
            padding: 0 40px;
        }

        & > div:nth-of-type(2) {
            gap: 0;
        }
    }
}