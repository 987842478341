.edit-skill-item-container {
    margin-top: 30px;
    width: 100%;
}

.edit-skill-item-container>p {
    margin: 0;
    font-weight: 500;
}

.skill-edit-form {
    position: relative;
    background-color: white;
    border-radius: 20px;
    padding: 20px 70px 20px 70px;
    width: 700px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skill-remove-btn {
    position: relative;
    font-size: 20px;
    color: #888;
    cursor: pointer;
    line-height: 0;
}


#skill-search {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
    display: block;
    transition: box-shadow 0.2s;
    box-sizing: border-box;
}


#skill-search:focus {
    outline-width: 0;
    box-shadow: 1px 1px 5px rgb(130, 130, 130);
}

#skill-suggestion-container {
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 10px 10px;
    box-shadow: 1px 1px 10px rgba(177, 177, 177, 0.31);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
}

#skill-suggestion-container>div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    gap: 20px;
    padding: 30px 0 30px 0;
}


#skill-suggestion-container>h3 {
    margin: 0;
    font-size: 20px;
}

#skill-suggestion-container>div>p {
    margin: 0;
    padding: 5px 20px 5px 20px;
    border-bottom: 1px solid #ccc;
    transition: box-shadow 0.2s;
    cursor: pointer;
}

#skill-suggestion-container>div>p:hover {
    box-shadow: 1px 1px 5px rgb(130, 130, 130);
}

@media only screen and (max-width: 855px) {
    .skill-edit-form {
        height: 100%;
        border-radius: 0;
        max-height: none;
        box-sizing: border-box;
        padding: 20px
    }

    #Skills-Edit-Container h1 {
        width: 100%;
    }
}