#podcast-page {
    height: calc(100vh - 103.5px);
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    & h1 {
        color: var(--main-accent-blue)
    }
}

.podcast-player {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 0;
}

.player-info-container {
    width: auto;
    height: auto;
    flex: 2;
    width: 40%;
    border-right: 2px solid #ccc;
    padding-right: 40px;

    & video {
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin-bottom: 20px;

        background-image: url('../../assets/PplLdr_graylogobanner.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.current-play-info {
    display: flex;
    justify-content: space-between;
}

.podcast-list-panel {
    flex: 2;
    padding: 40px;
    box-sizing: border-box;
    background-color: #fafafa;
    height: 100%;
    position: relative;

    & h4 {
        margin-bottom: 40px;
    }
}

.podcast-list-avail {
    height: auto;
    max-height: calc(50vh - 80px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    & button {
        border: none;
        background-color: var(--transparent);
        text-align: left;
        padding: 10px 30px 10px 0px;
        transition: color 0.2s, background-color 0.2s, padding 0.2s;

        & div:first-of-type {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            & span {
                color: var(--main-accent-blue);
                margin-left: 20px;
            }
        }
    }

    & button:hover {
        background-color: var(--main-accent-blue-hi);
        color: white;
        padding: 10px 30px 10px 30px;

        & div span {
            color: white
        }
    }

    .current-play {
        padding: 10px 30px 10px 30px;
        border-left: 2px var(--main-accent-blue-hi) solid;
    }
}

@media only screen and (max-width: 855px) {
    #podcast-page {
        min-height: 100vh;
        height: auto;
    }
    .podcast-player {
        flex-direction: column;
        margin-top: 40px;
        justify-content: space-between;
        gap: 60px;
    }
    
    .player-info-container {
        flex: 1;
        width: 100%;
        padding-right: 0;
        border: none;
    }
    
    .podcast-list-panel {
        flex: 1;
        width: 100%;
    }
}