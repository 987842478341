.post-card-user-field {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.post-card-user-field:hover * {
    text-decoration: underline;
}

.post-card-user-field h3 {
    font-size: 15px;
    margin: 0;
    margin-bottom: 4px;
}

.post-card-user-field p {
    font-size: 13px;
    margin: 0;
}

.leader-tag {
    background-color: var(--success-green);
    color: white;
    padding: 2px 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    border-radius: 4px ;
}