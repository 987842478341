#awards-section-wrapper {
    position: relative;
}
#awards-section-wrapper > button {
    position: absolute;
    height: 40px;
    width: 40px;
    background-color: rgba(0, 0, 0, 0);
    transition:  color 0.2s;
    top: 50%;
    transform: translate(0, -50%);
    border: 0;
    font-size: 30px;
    color:#777
}
#awards-section-wrapper > button:hover{
    color:var(--main-accent-blue)
}
#awards-section-wrapper > button:first-of-type{
    left: 0
}
#awards-section-wrapper > button:last-of-type{
    right: 0
}


#awards-container{
    display: flex;
    overflow-x: auto;
    box-sizing: border-box;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    gap: 20px;
    height: auto;
    width: auto;
    margin: 0 50px 0 50px
}

#awards-container::-webkit-scrollbar{
    height: 10px;
}
#awards-container::-webkit-scrollbar-track{
    background-color: rgba(228, 228, 228, 0.413);
}
#awards-container::-webkit-scrollbar-thumb{
    background-color: var(--main-accent-blue);
    border-radius: 10px;
}

.achievement-nav-btn {
    position: relative;
}

.achievement-item-container{
    height: 100%;
    min-width: calc(33.33% - 59px);
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    /* box-shadow: 2px 2px 5px rgba(142, 142, 142, 0.577); */
    margin-bottom: 7px;
}

.achievement-external-link {
    border-radius: 10px;
    /* box-shadow: 1px 1px 5px rgba(142, 142, 142, 0.577); */
    /* border: 1px solid #ccc; */
    padding: 30px;
}
.achievement-external-link:visited{
    color: black;
}
.achievement-external-link > p:first-of-type{
    color: black;
    margin: 0;
    font-weight: bold;
}
.achievement-file-link > p:visited {
    color: #777
}
.achievement-file-link > p{
    border-bottom: 1px solid var(--transparent);
    transition: color 0.2s, border-bottom 0.2s
}
.achievement-file-link > p:hover{
    color:var(--main-accent-blue);
    border-bottom: 1px solid var(--main-accent-blue);
}
.achievement-external-link > p:last-of-type, .achievement-file-link p {
    margin: 0 0 20px 0;
    color: #777
}

.achievement-item-container {
    overflow: hidden;
    display: inline;
}
.achievement-item-container img{
    display: block;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    border: 1px solid var(--transparent);
    transition: border 0.2s ease-in-out, transform 0.2s ease-in-out;
    border-radius: 10px;
}
.achievement-item-container img:hover{
    border: 1px solid #0076b5;
    transform: scale(1.02);
}

.education-form-container textarea{
    resize: vertical;
    height: 70px;
}

@media only screen and (max-width: 855px){
    .carousel-btn{
        display: none;
    }
    #awards-container{
        flex-wrap: wrap;
        width: 100%;
        margin: 0;
        justify-content: center;
        /* overflow-x: hidden; */
    }
    .achievement-item-container{
        width: 100%;
    }

}