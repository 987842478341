.forgot-password-container-page {
    background-color: #fafafa;
    height: 100vh;
}

.forgot-password-container {
    position: absolute;
    max-width: 400px;
    padding: 30px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 5px 5px 10px rgb(191, 191, 191);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.forgot-password-email-input[type="email"] {
    background-color: red;
}

.forgot-password-container h2 {
    margin-bottom: 10px;
}

.forgot-password-container p {
    margin-bottom: 20px;
}

.forgot-password-container .error-message {
    color: red;
    font-size: 14px;
}