.navbar-container{
    position:fixed;
    z-index: 100;
    width: 100vw;
}
.navbar {
    display: flex;
    align-items: center;
    background-color: #282828;
    padding: 10px 20px;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
}

.navbar *:visited{
    color: white
}

.home-icon {
    /* width: 35px; */
    position:relative;
    height: 35px;
    cursor: pointer;
}

.home-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
}

.nav-links {
    display: flex;
    gap: 20px;
    align-items: center;
}

.logged-in-links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.nav-bar-menu-container{
    opacity: 0;
    background-color: #282828;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: -1;
    right: 0;
    transform: translate(0, -100%);
    transition: 0.5s opacity, 0.6s transform;
    width:auto;

    & button:not(:first-of-type) i {
        color: white;
    }
}

.logged-in-links img{
    position: relative;
    box-sizing: border-box;
    height: 35px;
    width: 35px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

#nav-hamburger-menu{
    color: white;
    display: none;
}

@media (min-width: 800px){
    .logged-in-links:hover {
        .nav-bar-menu-container{
            opacity: 1;
            transform: translate(0, 45px);
        }
    }
}

.nav-links-not-logged{
    color: white
}

.nav-bar-menu-btn {
    background-color: var(--transparent);
    color: #fff;
    border: none;
    right: 0px;
    white-space: wrap;
    cursor: pointer;
    padding: 20px 20px 20px 20px;
    transition: 0.2s background-color;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.nav-bar-menu-btn > span {
    width: 30px
}

.nav-bar-menu-btn > p {
    width: 100px;
    margin: 0;
    text-align: left;
}

.nav-bar-menu-btn:hover {
    background-color: #555;
}

.nav-search{
    display: flex;
    align-items: flex-end;
    width: auto;
}

.nav-search-txt{
    position: relative;
    width: 50ch;
    padding: 5px 10px;
    border: 0
}

.nav-search-txt:focus{
    outline-width: 0;
    box-shadow: 1px 1px 5px var(--main-accent-blue);
}

.nav-search-button{
    border-radius: 4px;
    cursor: pointer;  
}
.nav-search-button i{
    margin-right: 10px;
    width: 30px;
}

.nav-beleader-button{
    border-radius: 4px;
    cursor: pointer;  
    white-space: nowrap;
    margin-left: 10px;
    transition: margin-left 0.4s ease;
}

.nav-search-results{
    position: relative;
    width: 75%;
    height:auto;
    max-height: 85vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.848);
    backdrop-filter: blur(30px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 20px 20px;
    transition: opacity 0.05s;

    & > p{
        color: white;
        margin-left: 20px;
    }

    & > .arrow-indicator{
        position: -webkit-sticky;
        position: sticky;
        left: calc(100% - 20px);
        bottom: 10px;
        color: white;
        width: 1ch
    }

    .note-info{
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
        color: white;
        padding: 20px;

        & p{
            margin: 0;
        }
    }

    .search-result-close {
        position: -webkit-sticky;
        position: sticky;
        font-size: 35px;
        left: calc(100% - 50px);
        top: 10px;
        color: rgb(210, 210, 210);
        width: 2ch;
        height: 0;
        cursor: pointer;
        transition: color 0.2s
    }
    .search-result-close:hover{
        color: white
    }
}

.more-results-btn {
    width: 100%;
    background-color: var(--transparent);
    border: none;
    color: #ccc;
    transition: 0.2s background-color;
    padding: 10px 0;
}

.more-results-btn:hover {
    background-color: rgba(81, 81, 81, 0.497);
}

.alert-icon {
    position: relative;
    color: #fff;
    top: 5px;
}

.alert-icon-txt {
    background-color: var(--warning-delete-hi);
    font-size: xx-small;
    position: absolute;
    padding: 3px;
    top: 2px;
    right: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-icon-container {
    width: 35px;
    height: 35px;
    position: relative;
    color: #fff;
}

.alert-icon-container:hover {
    cursor: pointer;
}

.invite-leader-modal {
    & input, select {
        box-sizing: border-box;
    }

    #link-field {
        position: relative;
        display: flex;
        align-items: center;
        gap: 20px;
        
        & i {
            color: #777;
            height: auto;
        }

        #copied-indicator {
            position: absolute;
            left: 28ch;
            top: -30px;
            color: var(--transparent);
            transition: color 0.2s ease-in-out;
        }
    }

    #pplldr-register-link {
        cursor:pointer;
    }

    .btn-close {
        width: fit-content;
    }

    & button:not([class="btn-close"]) {
        height: 100%;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        padding: 5px 10px;
        max-width: 200px;
        transition: 0.2s background-color;
    }

    #invite-leader-invite-btn { 
        background-color: var(--main-accent-blue);
    }

    #invite-leader-invite-btn:hover {
        background-color: var(--main-accent-blue-hi);
    }

    #invite-leader-invite-btn {
        transition: background-color 0.2s ease-in
    }
}

@media only screen and (max-width: 855px){
    .NAV-PAD{
        height: 55px;
    }
    .navbar{
        flex-basis: auto;
        flex-wrap: nowrap;
        padding: 10px 10px;
    }
    .nav-search{
        flex-shrink: 1;
        flex-grow: 1;
    }

    .nav-search-txt{
        width: 100%;
    }

    .nav-links {
        gap: 10px;
    }

    .alert-icon-container {
        display: none;
    }

    .nav-search-txt:focus + .nav-beleader-button > .theme-button {
        opacity: 0;
        max-width: 0px;
        padding: 5px 0px;
        letter-spacing: -1em;
        /* visibility: hidden; */
    }

    .nav-search-txt:focus + .nav-beleader-button {
        margin-left: 0px;
    }

    .nav-search-results{
        width: 100%;
        background-color: #282828;
        border: none
    }
    #nav-profile-btn{
        display: none;
    }
    #nav-hamburger-menu{
        display: block
    }

    .note-info *{
        font-size: 10px;
    }

    .logged-in-links:hover{
        .nav-bar-menu-container{
            transform: none
        }
    }
}