.profile-edit {
    .modal-dialog {
        min-width: 800px;
    }
}

#first-last-name-profile-edit {
    display: flex;
}

.edit-form-btn-group {
    display: flex;
    justify-content: space-around;
}

.name-row {
    gap: 50px;
}

#profile-edit-current-pfp{
    margin-left: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    flex-shrink: 0;
    object-position: center;
    object-fit: cover;
}

.pfp-del-btn {
    color: var(--warning-delete);
    transition: color 0.2s;
    height: fit-content
}

.pfp-del-btn:hover {
    color: var(--warning-delete-hi)
}

.update-btn {
    background-color: var(--main-accent-blue);
    color: #fff;
}

.update-btn:hover {
    background-color: var(--main-accent-blue-hi);
}

@media only screen and (max-width: 855px) {
    .profile-edit {
        .modal-dialog {
            min-width: 100vw;
        }
    }

    .name-row {
        gap: 0;
    }
}