.settings-page {
    display: flex;
    width: 100%;
    min-height: 100vh;
    background: white;
    box-sizing: border-box;

    & h4 {
        font-size: 20px;
        margin-top: 30px;
    }
}

.settings-sidebar {
    width: 20%;
    background-color: #f0f0f0;
    padding: 20px;
    border-right: 1px solid #ccc;
}

.settings-sidebar ul {
    list-style: none;
    padding: 0;
    cursor: pointer;
}

.settings-sidebar li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.settings-content {
    flex: 1;
    padding: 20px;
}

.security-settings,
.notification-settings {
    max-width: 855px;
    margin: 0 auto;
}

.notif-setting-card, .privacy-setting-card {
    background-color: #ffffff;
    padding: 20px;
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 3px solid #f7f7f7;
    box-sizing: border-box;

    & > p {
        margin: 0;
        text-align: left;
        flex: 1;
    }
}

.settings-info {
    color:#777;
    margin: auto;
    margin-top: 20px;
    padding-left: 20px;
}

.setting-save-change-button {
    margin: 20px auto;
    display: block;
}

.setting-pwd-header {
    border-bottom:2px solid #0077b5;
    display: flex;
    margin-top: 20px;
}

.setting-pwd-header :hover {
    cursor: pointer;
}

.sec-setting-btn {
    background-color: #0077b5;
    color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    width: 120px;
    font-size: 16px;
    display: block;
    font-weight:bold;
}

.setting-item{
    font-size: 16px;

    & i {
        margin-right: 10px;
    }
}

.selected {
    background-color: #0077b5;
    color: #ffffff;
    border-radius: 5px;
}

.notif-setting-header {
    margin-bottom: 20px;
}

.delete-account-btn {
    min-width: fit-content;
    background-color: var(--warning-delete);
}

.delete-account-btn:hover, .delete-account-btn:active {
    background-color: var(--warning-delete-hi);
}

@media only screen and (max-width: 855px) {
    .settings-sidebar {
        padding: 2px;
        width: fit-content;
    }

    .notif-setting-card, .privacy-setting-card {
        padding: 10px 0;
    }
}