/* Register.css */

.register-name-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0 50px;
    flex-wrap: wrap;

    & > div {
        flex: 1;
        min-width: 300px
    }
}

.register-form-container {
    box-sizing: border-box;
    height: auto;
    width: 100%;
    padding: 40px;
    background-color: white;

    display: flex;
    flex-direction: column;

    & h3 {
        color: var(--main-accent-blue);
    }
}

.verify-container{
    display: flex;
    & span{
        color: var(--main-accent-blue);
        font-weight: 700;
    }
}

.register-form > button {
    background-color: var(--main-accent-blue);
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 35%;
    align-self: center;
    margin-bottom: 20px;
}

.register-form-container button:hover {
    background-color: var(--main-accent-blue-hi);
}

.register-form-third-party-container{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 20px;
}
  
/* Auth Form */
.register-form {
    background-color: #fff;
    box-shadow: 5px 5px 10px rgb(191, 191, 191);
    border-radius: 20px;
    padding: 40px 70px;
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto 0 auto;

    & p:first-of-type {
        margin-right: auto;
    }
}
  
.register-form h2 {
    font-size: 24px;
    margin-bottom: 15px;
}

.legal-disclaimer-check-container{
    margin: 0 0 20px 0;
    & label{
        text-align: center;
    }
}
.legal-disclaimer-check-container input{
    margin-right: 15px;
}
.legal-disclaimer-check-container p{
    border-bottom: 1px solid #777;
}

.register-form-container a, .register-form-container a:visited {
    border-bottom: 0px solid var(--transparent);
    color: var(--main-accent-blue);
    transition: color 0.2s, border-bottom 0.2s
}

.register-form-container a:hover {
    color: var(--main-accent-blue);
    border-bottom: 1px solidvar(--main-accent-blue)
}


.legal-disclaimer-modal-container{
    top: 0;
    left: 0;
    z-index: 10000000000;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;
    overflow-y: hidden;
}

.legal-disclaimer-form-container{
    max-width: 500px;
    display: flex;
    flex-direction: column;
    white-space: wrap;
    position: relative;
    background-color: white;
    border-radius: 20px;
    padding: 20px 70px 20px 70px;
    align-items: center;
}

.legal-disclaimer-form-container h2{
    display: flex;
    align-items: flex-start;
}

.legal-disclaimer-form-container *:not(h2, button){
    text-indent: 50px;
}

.age{
    color:var(--main-accent-blue);
}

#register-OR{
    margin: 10px;
}

.block-register-agree{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.403);
    z-index: 1;
}

#google-register-container{
    z-index: 0;
}

.resend-email {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    & p {
        margin: 0
    }
}

@media only screen and (max-width: 855px){
    .register-form{
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 0;
    }
    .register-form-container{
        padding: 40px 0 0 0;
        align-items: center;
    }
}