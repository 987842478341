.field-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & input {
        width: 100%;
    }

    > label:first-of-type {
        white-space: nowrap;
    }
}

.field-container-check {
    width: 100%;
    height: 44px;
    
    .form-check {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: start;
        gap: 20px;
    }

    & input {
        padding: 0;
    }
}

.exp-date {
    display: flex;
    gap: 20px
}

#profile-exp-form input[type="textarea"] {
    resize: vertical;
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 0;
    outline: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
    font-size: 14px;
    flex: 5;
    transition: box-shadow 0.2s
}

input[name="currently_working"]:focus {
    background-color: black;
}

#profile-exp-form .checkbox-label {
    display: flex;
    /* Display checkbox input and label inline */
    align-items: center;
    /* Align checkbox input and label vertically */
}

#profile-exp-form .checkbox-label input {
    margin-right: 5px;
    /* Add some space between checkbox input and label */
}

@media only screen and (max-width: 855px) {
    .modal-content-exp {
        width: 100%;
        min-width: 0;
        padding: 20px;
        height: 100%;
        border-radius: 0;
        flex-grow: 1;
        max-height: fit-content;
        box-sizing: border-box;
    }

    .field-container {
        flex-direction: column;

        & label {
            align-self: flex-start;
        }
    }

    .exp-date {
        flex-wrap: wrap;
    }
}