#profile-main h3 {
    color: var(--main-accent-blue);
}

.user-profile-section {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    background-image: url("../../assets/sampleBG-min.jpg");
    background-color: white;
    background-size: contain;
    transform: translateZ(-1px) scale(2.5);
    background-repeat: repeat;
    background-position: center;
}

.user-profile-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 100%;
    margin: 0 0 0 0;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-image: linear-gradient(#f7f7f768, white, white, white);
    backdrop-filter: blur(5px);
    border-radius: 20px 20px 0 0;
    box-shadow: -10px 0px 50px rgba(0, 0, 0, 0.402);
}

.user-personal-info-container {
    flex-grow: 1;
    margin-left: 20px;
}

.user-personal-info-container>p {
    margin: 0;
}

.profile-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-heading h2 {
    font-size: 30px;
    color: black;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #ccc;
    margin-top: 0px;
}

.user-profile-image-small-container {
    display: flex;
    height: 125px;
}

/* Profile Image */
.user-profile-image-small {
    border: 7px #f7f7f7d7 solid;
    backdrop-filter: blur(5px);
    transform: translate(0, -50%);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}   

.user-profile-image-small-premium {
    border: 7px var(--golden) solid;
    box-shadow: 0 0 20px var(--golden);
}

#profile-bio {
    font-weight: 600;
    font-size: 17px;
    overflow-wrap: break-word
}

#profile-location {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    flex-wrap: wrap;

    & button:nth-of-type(2) {
        margin-left: auto;
    }
}

#profile-location > p {
    color: #777;
    margin: 0;
}

.profile-contact-btn {
    padding: 0;
    margin: 0;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 0 solid rgba(0, 0, 0, 0);
    border: 0;
    height: 24px;
    transition: height 0.2s, border-bottom 0.2s
}

.profile-contact-btn p {
    color: var(--main-accent-blue);
    font-size: 16px;
    margin-bottom: 0;
}

.profile-contact-btn:hover {
    border-bottom: 1px solid var(--main-accent-blue);
    height: 30px;
}

/* Heading and Add Button */
.experience-section>h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 25px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    height: auto;
}

.add-experience-btn {
    background-color: rgba(0, 0, 0, 0);
    color: #777;
    border: none;
    border-bottom: 0 solid rgba(0, 0, 0, 0);
    font-size: 18px;
    padding: 0px;
    cursor: pointer;
    height: 18px;
    transition: height 0.2s, border-bottom 0.2s, color 0.2s;
    margin: 0 30px 0 auto;
}

.add-experience-btn:hover {
    border-bottom: 1px solid var(--main-accent-blue);
    color: var(--main-accent-blue);
    height: 25px;
}

.profile-edit-experience-btn {
    background-color: rgba(0, 0, 0, 0);
    color: #777;
    border: none;
    border-bottom: 0 solid rgba(0, 0, 0, 0);
    font-size: 18px;
    padding: 0px;
    cursor: pointer;
    height: 18px;
    transition: height 0.2s, border-bottom 0.2s, color 0.2s;
}

.profile-edit-experience-btn:hover {
    border-bottom: 1px solid #2f2f2f;
    color: #2f2f2f;
    height: 25px;
}


/* Container for Experience Cards */
.experience-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 20px;
}

.skill-item-container {
    display: flex;
    gap: 20px;
    width: 50vw;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: space-between;
}

.skill-item {
    background-color: #fff;
    display: flex;
    gap: 15px;
    padding: 5px 20px;
    border: 1px #ccc solid;
    border-radius: 20px;
    box-shadow: 2px 2px 5px rgba(142, 142, 142, 0.577);
    display: flex;
    align-items: center;
}

.skill-item>p {
    color: black;
    margin: 0;
}

@media only screen and (max-width: 855px) {
    .user-profile-image-small-container {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .user-profile-container {
        background-image: linear-gradient(#f7f7f768, white, white, white, white, white, white);
    }

    .user-profile-image-small {
        transform: translate(0, 0);
    }

    #profile-bio,
    #profile-location {
        position: relative;
    }

    .profile-see-score-btn {
        margin-right: auto;
    }

    .user-personal-info-container {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .experience-cards {
        justify-content: center;
    }

    .skill-item-container {
        width: 100%;
    }

    .skill-item {
        box-shadow: none;
    }
}