.contact-us-container {
    background-color: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    box-sizing: border-box;

    & h1 {
        color: var(--main-accent-blue)
    }
}

.contact-us-form {
    margin: 40px auto 0 auto;
    max-width: 855px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    background-color: #fff;
    border-radius: 20px;
    padding: 40px 70px;

    & button {
        color: white;
        background-color: var(--main-accent-blue);
        transition: 0.2s background-color;
    }

    & button:hover {
        background-color: var(--main-accent-blue-hi);
    }

    & textarea {
        box-shadow: none;
    }

    & textarea:focus {
        box-shadow: 1px 1px 5px rgb(130, 130, 130);
    }

    & select {
        font-size: 16px;
        width: 50%;
        padding: 10px 10px 10px 10px;
        margin: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0px;
        display: block;
        transition: box-shadow 0.2s;
        box-sizing: border-box;
        background-color: var(--transparent);
        outline: none;
    }
}

.two-column-profile-edit {
    width: 100%;
    display: flex;
    justify-content: space-between;
    & > div {
        width: 100%;
    }
}

.contact-us-submitted-info-container {
    background-color: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 100px);
    box-sizing: border-box;

    & h1 {
        color: var(--main-accent-blue)
    }

    & table {
        margin: 40px 0 40px 0;
    }

    & button {
        color: white;
        width: 100px;
        background-color: var(--main-accent-blue);
        transition: 0.2s background-color;
    }

    & button:hover {
        background-color: var(--main-accent-blue-hi);
    }
}

.success-content-message {
    max-width: 500px;
}

@media only screen and (max-width: 855px) {
    .contact-us-form {
        margin: 40px 0 0 0;
        width: 100vw;
        border-radius: 0;
        padding: 40px 20px;
        transform: translateX(-40px);
    }

    .two-column-profile-edit {
        flex-direction: column;
        gap: 0;
    }
}