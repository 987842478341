.post-to-rating-option {
    & i:first-of-type {
        margin-right: 10px;
    }
}

.post-to-rating-edit-post-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.post-highlighted {
    box-shadow: 2px 2px 10px var(--main-accent-blue-hi)
}

@media only screen and (max-width: 855px) {
    .post-highlighted {
        border: 2px solid var(--main-accent-blue-hi);
        border-radius: 10px;
    }
}