.notif-homebody {
    width: 100%;
    margin: auto;
    padding-top: 1px;
    text-align: center;
    background: white;
}

.notifs-container {
    width: 60%;
    margin: auto;
    margin-top: 30px;
    min-height: 100vh;
    box-sizing: border-box;
    height: auto;
    display: flex;
    flex-direction: column;
}

.notif-card {
    background-color: #ffffff;
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.184)
}

.notif-card:hover {
    cursor: default;
}

.notif-top-bar {
    width: 100%;
    margin-left: 10px;
    float: right;
}

.notif-top-func-bar {
    width: 50%;
    display: flex;
    margin-left: 10px;
    align-content: space-between;
    gap: 20px;
    float: right;
}

.notif-tabs {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
}

.all-read-btn {
    background-color: var(--transparent);
    color: black;

    &:first-of-type {
        margin-left: auto;
    }
}

.all-read-btn:hover {
    color: white
}

.notif-tabs button {
    border-radius: 5px 5px 0 0;
    width: 60px;
    border: 1px solid #e1e1e1;
}

.btn-active {
    background-color: var(--main-accent-blue);
    color: #ffffff;
    border: 1px solid #e1e1e1;
}

.no-notif-bg {
    position: relative;
    width: 100%;
    margin-top: 100px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    & p {
        margin: 0;
    }
}

.notif-datetime {
    font-size: x-small;
    float: right;
}

.blue-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--main-accent-blue);
    margin-right: 10px;
    margin-top: 8px;
}

.gray-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: gray;
    margin-right: 10px;
    margin-top: 8px;
}

.notif-head {
    text-align: left;
}

.notif-content {
    margin: 0;
    margin-left: 10px;
    margin-top: 1px;
    text-align: left;
    flex: 1;
    white-space: pre-wrap;
}

.notif-pagination {
    margin: 20px auto;
    width: 100%;
    display: flex;
    justify-content:center;
    gap: 20px;
}

.notif-pagination button{
    background-color: rgba(0, 0, 0, 0);
    color: var(--main-accent-blue);
    border: none;
    border-bottom: solid;
    font-size: 16px;
    padding: 0px;
    cursor: pointer;
    height: 18px;
}

@media only screen and (max-width: 855px){
    .notifs-container {
        width: 100%;
        margin: 0px;
        padding: 20px;
    }
}