.bottom-nav {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    background-color: #282828;
    padding: 8px 0;
    margin-bottom: 0;

    & a {
        color: white;
    }
}

.nav-items {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1px;
    gap: 30px;
    text-decoration: none;
    font-size: 11px;
    white-space: no-wrap;
}

.nav-item {
    width: auto
}

.nav-link:focus {
    color: white
}

.footer {
    text-align: center;
}

.footer p {
    font-size: 10px;
    margin: 0;
}

.footer .copyright {
    color: white
}

@media only screen and (max-width: 855px) {
    .nav-items {
        flex-direction: column;
        gap: 2px;
        margin-bottom: 10px;
    }
}