.manage-plan-page-container {
    background-color: white;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    & h4 {
        font-size: 18px;
    }

    & div:first-of-type {
        padding-bottom: 20px;
        border-bottom: 1px solid #ccc;
    }

    & a {
        text-align: center;
        width: 250px;
        padding: 10px 30px;
        color: white;
        text-decoration: none;
        outline: none;
        background-color: var(--main-accent-blue);
        border-radius: 5px;
        box-shadow: 0px 0px 0px var(--transparent);

        transition: background-color 0.2s, box-shadow 0.2s
    }

    & a:hover {
        background-color: var(--main-accent-blue-hi);
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.332);
    }
}

.site-heading h2 {
    display: block;
}

.success-payment-body {
    width: 100%;
    margin: auto;
    min-height: 100vh;
    background: #f7f7f7;
    text-align: center;
    padding-top: 40px;
}

.cancel-confirm-body {
    width: 100%;
    margin: auto;
    min-height: 100vh;
    background: #f7f7f7;
    text-align: center;
    padding-top: 40px;
}


.plans-container {
    width: 100%;
    min-height: calc(100vh - 100px);
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px 50px;
    box-sizing: border-box;
    gap: 50px;
}

.plans-enroll-row {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
}

.plan-desc {
    border-radius: 30px;
    box-shadow: 5px 5px 10px rgb(191, 191, 191);
    width: 35%;
    min-width: 300px;
    padding: 50px 50px;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & h3 {
        align-self: center;
        margin-bottom: 30px;
    }

    & i {
        margin-right: 20px;
    }

    & p {
        margin: 0;
        line-height: 35px;
        white-space: wrap;
    }

    & button {
        margin-top: auto;
        align-self: center;
    }
}

.subscription-title{
    border-bottom: 1px solid #ccc;
    text-align: center;
    padding-bottom: 20px;

    & h4 {
        font-size: 18px;
    }
}

.price-row {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 15px;
}

.plans-cancel-row {
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.plan-item {
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 25px;
    padding: 20px;
    margin: 10px;
    width: 25vw;
    height: 70vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.footer-btns {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.set-plan-button {
    width: 40%;
    border-radius: 10px;
    padding: 10px 15px;      
    background-color: var(--main-accent-blue);
    border: none;
    color: #fff;
    margin: 0;
}

.current-plan-btn {
    border-radius: 10px;
    width: 60%;
    height: 30px;
    background-color: #ccc;
    border: none;
    color: #fff;
    margin: 0;
}

.plan-benefit {
    height: 75%;
}

.keep-plan-button {
    border-radius: 10px;
    width: 40%;
    height: 30px;
    background-color: #fff;
    border: 2px solid var(--main-accent-blue);
    color: var(--main-accent-blue) ;    
    padding: 7px 12px; 
}

@media only screen and (max-width: 855px) {
    .plans-container {
        padding: 20px;
    }
}