.about-us-container {
    padding: 40px;
    background-color: white;
    & h1 {
        color: var(--main-accent-blue);
        text-align: center;
        margin-bottom: 40px;
    }



    & span {
        font-weight: 700;
    }
}

.about-intro {
    margin: 0 100px 40px 100px;
}

.about-us-layout {
    display: flex;
    align-items: center;
    padding: 40px;
    border-top: 1px solid #ccc;
    gap: 40px;

    & h2 {
        flex: 1;
        text-align: center;
    }

    & p, > div {
        flex: 2;
    }
}

.checkbox-label {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
}

.checkbox-label input {
    margin-top: 0;
    margin-right: 10px;
}

.terms {
    width: 90%;
    margin: auto;
}

@media only screen and (max-width: 855px) {
    .about-intro {
        margin: 0 0 40px 0;
    }

    .about-us-layout {
        flex-direction: column;
        padding: 40px 0 40px 0
    }
}

.privacy-policy-container {
    padding: 40px;
    background-color: white;
    & h1 {
        color: var(--main-accent-blue);
        margin-bottom: 40px;
    }

    & h2 {
        font-size: 25px;
    }

    & h3 {
        font-size: 18px;
    }

    & p {
        text-align: justify;
    }

    & a {
        color: var(--main-accent-blue)
    }
}