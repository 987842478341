.book-rec-body {
    width: 100%;
    min-height: 100vh;
    background: white;
    padding: 40px;
    box-sizing: border-box;
    position: relative;
}

.book-rec-form{
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    flex-wrap: wrap;
    align-items: flex-end;

    & div {
        flex: 1;
        min-width: 40ch;
        max-width: 40ch;
        box-sizing: border-box;
        height: auto;
    }

    & button {
        margin-bottom: 10px;
    }
}

.book-rec-info {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    & p:last-of-type {
        text-align: right;
    }
}

.book-rec-list {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    gap: 40px;
    position: relative;

    > div {
        padding: 20px;
        max-width: calc(50vw - 60px);
        min-width: calc(50vw - 60px);
        flex: 1;
        border-radius: 20px;
        border: 1px solid #ccc;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 855px) {
    .book-rec-form {
        width: 100%;
        box-sizing: border-box;
        position: relative;
        justify-content: center;

        & div {
            position: relative;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
        }

        & input, select {
            box-sizing: border-box;
            width: 100%;
        }
    }

    .book-rec-list {
        > div {
            min-width: 100%;
            max-width: none;
        }
    }
}