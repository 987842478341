.teamdashboard-page {
    display: flex;
    width: 100%;
    min-height: 100vh;
    background: white;
    padding: 20px;
    box-sizing: border-box;
}

.teamdashboard-sidebar {
    width: 20%;
    background-color: #f0f0f0;
    padding: 20px;
    border-right: 1px solid #ccc;

    & ul {
        list-style: none;
        padding: 0;
        cursor: pointer;
    }

    & li {
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }
}


.teamdashboard-content {
    flex: 1;
    padding: 10px;
}

.teamdashboard-item {
    font-size: 16px;
}

.selected {
    background-color: #0077b5;
    color: #ffffff;
    border-radius: 5px;
}