.education-item-container{
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(142, 142, 142, 0.577);
    width: calc(50% - 55px);
    min-width: 300px
}

.education-item-container > h3{
    margin-bottom: 0;
}

.degree-info-field{
    display: flex;
    gap: 15px;
}

.degree-info-field > p{
    color: #777;
    margin: 5px 0 0 0;
}

.gpa-field{
    margin-left: auto;
    display: flex;
    gap: 10px;
}

.gpa-field > p {
    margin: 0;
}

.gpa-field > p:first-of-type{
    font-weight: bold;
}

.education-duration{
    display: flex;
    gap: 10px;
}

.education-duration > p {
    margin: 5px 0 0 0;
}

.education-achievements{
    font-weight: bold;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin: 20px 0 10px 0;
}

.education-achievements-list-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.education-achievement-item{
    background-image: linear-gradient(-25deg,#f7f7f7, #e8f6fe);
    padding: 20px 20px 20px 20px;
    height: auto;
    border-radius: 20px;

    & h4{
        font-size: 18px;
    }
}

.education-achievement-item > div{
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.education-achievement-item > div > *{
    margin: 10px 0 0 0;
    line-height: 23px;
}

.education-achievement-item textarea{
    resize: vertical;
}

.education-achievement-item > div > i{
    align-self: flex-start;
}

/*

MODAL

*/
#education-edit-modal-container{
    top: 0;
    left: 0;
    z-index: 10000000000;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;
    overflow-y: auto;
}

.education-form-container{
    position: absolute;
    background-color: white;
    border-radius: 10px;
    padding: 20px 70px 20px 70px;
    width: 700px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 550px;
}

.education-form-container h3 {
    align-self: flex-start;
    width: 100%;
    border-bottom: 1px solid var(--main-accent-blue);
    box-sizing: border-box;
}

#degree-gpa-edit, #education-start-end-date {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;

    & > div {
        width: 100%;
    }
}

#gpa-field input:first-of-type{
    width: 6.5em;
}
#gpa-field input:last-of-type{
    width: 9em;
}

#gpa-field > div{
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
        line-height: 100%;
    }
}

.edit-education-achievements-list-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-bottom: 30px;
}

.education-achievement-add-btn{
    display: flex;
    justify-content: flex-end;

    & > button {
        margin: 20px 0
    }
}

.education-achievement-item{
    box-shadow: 2px 2px 7px rgba(142, 142, 142, 0.331);
}

.education-achievement-item input, .edit-education-achievement-desc{
    font-size: 16px;
    border: none;
    border-bottom: #ccc 1px solid;
    padding: 10px;
    margin-bottom: 10px;
}
.education-achievement-item input:focus, .edit-education-achievement-desc:focus{
    outline-width: 0;
}

.education-achievement-item > div > input:first-of-type{
    flex: 1
}
.education-achievement-item > div > input:last-of-type{
    width: 5em
}

.edit-education-achievement-desc {
    margin-bottom: 10px;
    width: calc(100% - 20px);
}

@media only screen and (max-width: 855px){
    .education-item-container{
        box-shadow: none;
        width: 100%;
    }
    .education-form-container{
        width: 100%;
        max-height: none;
        height: 100vh;
        flex-direction: column;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 0;

        & h3 {
            width: 100%;
            
        }
    }
    #degree-gpa-edit, #education-start-end-date{
        flex-wrap: wrap;
    }
    #gpa-field > div{
        justify-content: center;
        gap: 10px;
    }
    .education-achievement-item > div{
        gap: 0
    }
}