.landing-container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-evenly;
    align-items: center;
    min-height: calc(100vh - 70px - 2rem - 16px);
    height: auto;
    background-color: white;
    padding: 40px
}

.landing-content {
    padding: 0;
    max-width: 600px;
    height: auto;
}

.landing-header {
    font-size: 36px;
    font-weight: bold;
    margin: 0 0 20px 0;
}

.landing-subtext {
    font-size: 18px;
    color: #666;
    margin-bottom: 40px;
}

.login-section {
    background-color: #ffffff8a;
    backdrop-filter: blur(10px);
    padding: 40px;
    border-radius: 20px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.155);
}

.login-section input {
    box-sizing: border-box;
}


.login-section h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.form-group-landing {
    margin-bottom: 20px;
}

.form-group-landing label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group-landing input {
    width: 100%;
    padding: 10px;
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
    display: block;
    transition: box-shadow 0.2s;
    background-color: var(--transparent)
}

.form-group-landing input:focus {
    outline-width: 0;
    box-shadow: 1px 1px 5px rgb(130, 130, 130);
}

.login-button {
    padding: 5px 40px;
    white-space: nowrap;
}

.landing-image {
    min-width: 300px;
    width: 40%;
    height: auto;
    margin: 0 20px 40px 20px;
}

.password-pass {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & p {
        margin: 0
    }
}

.register-link-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid #ccc 1px;
    padding: 8px 10px;
    border-radius: 50px;
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;

    & p {
        margin: 0;
        color: #777
    }
}

.password-pass a {
    color: #666;
}

.error-message {
    color: rgb(214, 39, 0)
}

#divider {
    margin-top: 20px;
    border-top: 1px solid rgb(194, 194, 194);
    width: 100%;
    text-align: center;
}

#third-party-login-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    gap: 20px;
}

@media only screen and (max-width: 855px) {
    .landing-container {
        padding: 0px;
        padding-top: 40px;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: 40px
    }

    .landing-image {
        width: 90%;
        box-sizing: content-box;
    }

    .landing-header,
    .landing-subtext {
        display: none;
    }

    .landing-content {
        width: 100%;
        box-sizing: content-box;
    }

    .login-section {
        width: 100%;
        box-sizing: border-box;
    }

    #third-party-login-container {
        flex-direction: column;
    }

    .password-pass {
        flex-direction: column;
        gap: 10px;

        & > div {
            align-items: center;
        }
    }
}