.switch-container {
    width: 50px;
    height: 25px;
    background-color: #ccc;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
}
  
.switch-button {
    width: 20px;
    height: 20px;   
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
}
  
.switch-container.on {
    background-color: var(--success-green); 
}
  
.switch-container.off {
    background-color: #ccc; 
}
  
.switch-container.on .switch-button {
    transform: translateX(28px); 
}
  
.switch-container.off .switch-button {
    transform: translateX(2px); 
}