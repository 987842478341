.grouped-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.create-post-card,
.search-card,
.add-team-card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 600px;
    font-family: Arial, sans-serif;
    display: flex;
    align-items: flex-start;
}

.create-post-card {
    flex-direction: column;
    width: 500px;
    height: auto;
}

.post-content-input {
    width: 100%;
    border: none;
    font-size: 16px;
    resize: none;
    cursor: pointer;
    box-sizing: border-box;
    margin: 15px 0 15px 0;
    transition: height 0.1s ease-out;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
}

.post-content-input:focus {
    outline: none;
}

.post-button {
    font-family: 'Nunito Sans', sans-serif;
    padding: 8px 20px;
    background-color: #0075bb;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: auto;
    margin-top: 20px;
}

.post-button:hover {
    background-color: var(--main-accent-blue-hi);
}

#divider {
    height: 0px;
    max-width: 600px;
    margin: 0;
}

.create-post-attachments {
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: flex-end;
}

.create-post-attachments input {
    display: none;
}

.post-content-preview-image {
    width: 100%;
    margin-bottom: 20px;
}

.add-attachment-button, .remove-attachment-button {
    background-color: var(--transparent);
    color: #ccc;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: color 0.3s;
}

.add-attachment-button i {
    margin-right: 10px;
    color: #ccc;
    transition: color 0.3s;
}

.add-attachment-button:hover,
.add-attachment-button:hover i {
    color: var(--main-accent-blue-hi)
}

.remove-attachment-button:hover {
    color: var(--warning-delete-hi)
}

.create-post-card {
    /* Add flex-direction to arrange elements in a column */
    display: flex;
    flex-direction: column;
}

/* Center the posts on the page */
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
}

.post-card-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

.post-card-container::-webkit-scrollbar {
    width: 5px;
}

.post-card-container::-webkit-scrollbar-track {
    background-color: rgba(228, 228, 228, 0.413);
}

.post-card-container::-webkit-scrollbar-thumb {
    background-color: var(--main-accent-blue);
    border-radius: 10px;
}

/* Add some spacing between the buttons and the post content */
.post-content-with-buttons {
    margin-bottom: 20px;
}

/* Search card */
.search-card {
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
}

.search-card h2 {
    font-size: 20px;
    margin-bottom: 10px;
}

.search-card input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.teammates-grp {
    display: flex;
    padding-left: 10%;
}

.homebody {
    width: 100vw;
    background-color: white;
    min-height: 100vh;
    padding-top: 20px;
    position: relative;
}

#premium-btn {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-bottom: 20px;
}

.book-recommendation-link {
    background-color: var(--transparent);
    color: var(--golden);
    transition: background-color 0.2s, color 0.3s ease-out;
    border: none;
    padding: 20px 20px 20px 10px;
    display: flex;
    gap: 10px;
    justify-content: flex-start;

    & span {
        width: 30px
    }
}

.book-recommendation-link i {
    color: var(--golden);
    transition: color 0.35s ease-out;
}

.book-recommendation-link:hover {
    background-color: var(--golden);
    color: white;
}

.book-recommendation-link:hover i {
    color: white;
}

.create-post-panel-row {
    width: 100%;
    height: 100%;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
}

.user-head {
    width: 20%;
}

.featured-leaders-container {
    position: fixed;
    display: flex;
    padding: 20px 40px;
    top: 55px;
    height: calc(100vh - 55px);
    width: 200px;
    background-color: #f7f7f7;

    flex-direction: column;
    align-items: center;
    gap: 30px;
    overflow-y: auto;

    overscroll-behavior: none;
}

.featured-leaders-container::-webkit-scrollbar {
    width: 2px;
}
.featured-leaders-container::-webkit-scrollbar-thumb,
.featured-leaders-container::-webkit-scrollbar-track {
    background-color: var(--transparent);
    transition: 2s background-color;
}
.featured-leaders-container:hover::-webkit-scrollbar-thumb {
    background-color: #ccc;
}
.featured-leaders-container::-webkit-scrollbar-track{
    background-color: rgba(228, 228, 228, 0.413);
}

/* 140px is the width of the cards */
@media only screen and (max-width: calc(855px + 250px)) { 
    .featured-leaders-container {
        position: relative;
        height: auto;
        width: auto;
        box-sizing: border-box;
        margin-bottom: 20px;

        top: 0;
    
        flex-direction: row;
        overflow-x: auto;
    
        overscroll-behavior: auto;
    }

    .featured-leaders-container::-webkit-scrollbar {
        height: 0;
    }
}

@media only screen and (max-width: 855px) {
    .homebody {
        padding-top: 0;
    }

    .create-post-card {
        width: 100%;
        box-sizing: border-box;
        border: none;
        border-radius: 0;
        box-shadow: none;
        border-top: 1px #ccc solid;
        max-width: none;
    }

    #divider {
        display: none;
    }

    #premium-btn {
        display: none;
    }

    .post-card-container {
        width: 100%
    }

    .featured-leaders-container {
        margin-bottom: 0;
    }
}
