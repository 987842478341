.posts {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
}

.login-wall {
    border-radius: 20px;
    box-sizing: border-box;
    border: 1px #ccc solid;
    padding: 50px;
    display: flex;
    width: fit-content;
    max-width: calc(300px + 100px + 2px);
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto 0 auto;

    & a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 10px;
        border-radius: 50px;
        width: 300px;
        max-width: 300px;
        margin: 20px auto 0 auto;
        box-sizing: border-box;

        & p {
            margin: 0;
        }
    }

    & a:last-of-type {
        border: solid #ccc 1px;
        color: #777
    }

    .to-sign-in {
        color: white;
    }
}

@media only screen and (max-width: 855px){
    .login-wall {
        max-width: 100vw;
        left: 0;
        border: none;
    }
}