.rating-page-container {
    padding: 40px;
}
.panel-row {
    width: 100%;
    min-height: 100vh;
    background: white;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    gap: 70px;
}

.to-profile {
    margin-right: auto;
    color: #ccc;
    border: none;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--transparent);
    transition: 0.2s color, 0.2s border-bottom;
    background-color: var(--transparent);
}

.linkedin-share-btn {
    position: absolute;
    right: 0;
    background-color: var(--transparent);
    border: none;

    & img {
        height: 30px;
        transition: 0.2s height, 0.2s width, filter;
        filter: grayscale(100%)
    }

    &:hover img {
        height: 35px;
        filter: grayscale(0)
    }
}

.to-profile:hover {
    color: var(--main-accent-blue-hi);
    border-bottom: 1px solid var(--main-accent-blue-hi);
}

.leader-sub-panel-row {
    box-sizing: border-box;
    max-width: 60vw;
    width: auto;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px 20px;
    flex: 3
}

.leader-head {
    position: relative;
    width: auto;
    height: fit-content;
    padding: 0 40px 0 40px;
    text-align: center;
}

.leaderscore-profile-img {
    width: 80%;
    margin: auto;
}

.lead-info-rating {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#lead-info-badge{
    height: 50px
}

.progress-score-container{
    box-sizing: border-box;
    width: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 200px;
}

.no-progress-score-container{
    box-sizing: border-box;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap: 40px;
    flex: 1;
    min-width: 200px;
}

.rating-result-btn-grp {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
    width: fit-content;

    & button {
        width: 100%;
    }
}

.leader-score-attribute-panel{
    flex: 2
}

.ratingbar {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 5px;
    font-size: 10px;
    border-radius: 15px;

    & div {
        font-size: 15px;
        background-color: var(--main-accent-blue);
    }
}

.question-header-score{
    display: flex;
    justify-content: space-between;

    & p:last-of-type {
        font-weight: 700;
    }
}

.rating-row {
    height: auto;
    margin-top: 20px;
    width: auto;
    display: flex;
    flex-direction: column;

    .total-raters{
        font-size: 10px;
        margin: 0;
    }
}

.questions-row {
    height: 30px;
}


/* Profile Image */
.user-prof-img-small {
    border: 7px #f7f7f7d7 solid;
    backdrop-filter: blur(5px);
    transform: translate(0, -10%);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 20px;

    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    transition: 0.2s box-shadow, 0.2s width, 0.2s height;
}

.user-prof-img-small:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.172);
    width: 160px;
    height: 160px;
}

.post-to-rating-comment {
    display: flex;
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;

    & form{
        width: 100%;

    }

    & textarea{
        resize: none;
    }
}

.post-to-rating-comment textarea {
    width: 100%;
    border: none;
}

.post-to-rating-comment textarea:focus {
    outline: none;
}

.post-to-rating-comment button {
    float: right;
}

.post-to-rating-user {
    width: 10%;
}

.post-to-rating-user img {
    height: 35px;
    width: 35px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.post-to-rating-panel {
    width: 100%
}

.invite-teammember-btn {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    /* text-align: center; */
}

.invite-teammember-btn :hover {
    cursor: pointer;
    color: var(--main-accent-blue);
}

.loading-body {
    width: 100%;
    margin: auto;
    height: 100vh;
    background: #f7f7f7;
    text-align: center;
    padding-top: 30px;
}

.rating-attr-tooltip {
    position: relative;
    display: inline-block;
    
    & span {
        padding: 20px;
        visibility: hidden;
        width: 400px;
        background-color: #f7f7f7d0;
        backdrop-filter: blur(5px);
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.143);
        color: #444;
        text-align: left;
        border-radius: 6px;
        overflow-wrap: break-word;
        position: absolute;
        z-index: 1;
        bottom: 50%;
        font-size: 16px;
        transform: translateY(50%);
        right: 110%;
    }
}

.rating-attr-tooltip:hover span{
    visibility: visible;
}

.quesiton-attr-tip {
    display: flex;
    width: 100%;
}

@media only screen and (max-width: 855px){
    .rating-page-container {
        padding: 20px 0;
    }

    .progress-score-container {
        padding: 0 20px;
    }

    .panel-row{
        flex-wrap: wrap;
    }

    .leader-sub-panel-row {
        max-width: 100vw;
        width: 100vw;
    }

    .post-to-rating-comment{
        width: 100%;
        box-sizing: border-box;
    }

    .rating-attr-tooltip:hover span {
        visibility: hidden;
    }
}