.featured-leader-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: white;

    width: 140px;
    height: auto;
    padding: 0 20px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.155);

    cursor: pointer;
    transition: 0.2s width, 0.2s padding, 0.2s box-shadow;

    & img {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        object-fit: cover;
    }

    & h5 {
        text-align: center;
    }
}

.featured-leader-card:hover {
    width: 150px;
    padding: 0 20px 30px;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.155);
}

.featured-leader-card:last-of-type {
    margin-bottom: 40px;
}

@media only screen and (max-width: calc(855px + 250px)) {
    .featured-leader-card {
        justify-content: flex-start;
        height: auto;
        width: 200px;

        & h5 {
            white-space: nowrap;
        }

        & p {
            display: none;
        }
    }

    .featured-leader-card:last-of-type {
        margin-bottom: 0;
    }

    .featured-leader-card:hover {
        width: 200px;
        padding: 0 20px 20px;
        box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.155);
    }
}