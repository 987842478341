@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&family=Quicksand:wght@300;400;500;600;700&family=Raleway:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200;300;400;500&display=swap');

body {
    margin: 0;
    width: 100vw;
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior: none;
}
/* 
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

h4, h5, h6 {
    font-family: 'Lexend Deca', sans-serif;
    font-weight: 500;
}

h1, h2, h3 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
}

a {
    text-decoration: none;
    color: var(--main-accent-blue)
}

a:hover, a:focus {
    color: var(--main-accent-blue-hi)
}

*, code, pre {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
}

#root{
    overflow-y: hidden;
}

:root{
    --main-accent-blue: #0077b5;
    --main-accent-blue-hi: #0087d0;
    --warning-delete: #cf2a2a;
    --warning-delete-hi: #ff6666;
    --success-green: rgb(60, 161, 60);
    --dark-bg: #282828;
    --transparent: #00000000;
    --golden: rgb(224, 185, 80);
}