.profile-edit {
    .modal-dialog {
        min-width: 800px;
    }

    .form-footer-btn-group {
        border-top: 1px #ccc solid;
        margin-top: 30px;
        padding-top: 10px;
        display: flex;
        width: 100%;
        justify-content: flex-end;
        gap: 10px;
    }

    .two-column-profile-edit {
        width: 100%;
        display: flex;
        justify-content: space-between;
        & > div {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 855px) {
    .profile-edit {
        .modal-dialog {
            min-width: 100vw;
        }
    }
    .two-column-profile-edit {
        flex-direction: column;
        gap: 0;
    }
}