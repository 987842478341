.leader-card {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    gap: 20px;
    background-color: var(--transparent);
    transition: background-color 0.2s;
    cursor: pointer;

    & * {
        color: white;
    }
}

.leader-card:hover {
    background-color: rgba(255, 255, 255, 0.115);
}

.leader-score {
    display: flex;
    flex-direction: column;
    width: 150px;
    padding: 5px 20px;
    align-content: center;
    justify-content: center;
    position: relative;
    border-radius: 10px;
    z-index: 100;

    & * {
        text-align: center;
    }

    & h5 {
        font-size: 15px;
    }

    & p {
        margin: 0
    }

    .leader-result-view-score{
        font-size: 0px;
        transition: font-size 0.2s
    }
}

.leader-score:hover{
    .leader-result-view-score{
        font-size: 15px;
    }
}

.leader-info {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;

    > & div {
        flex-shrink: 1;
    }

    & img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        flex-shrink: 0;
        object-fit: cover;
        object-position: center;
    }

    & h3 {
        font-size: 25px;
        margin: 0;
    }

    & p {
        margin: 0
    }
}

@media only screen and (max-width: 855px) {
    .leader-info {
        & img {
            flex-basis: 30px;
            height: 30px;
            width: 30px;
        }

        ;

        & h3 {
            font-size: 15px;
        }

        ;

        & p {
            font-size: 10px;
        }
    }

    .leader-score {
        width: 100px;
        height: min-content;
        padding: 2px 10px;

        & * {
            text-align: center;
        }

        & h1 {
            font-size: 17px;
            margin: 0;
        }

        & h5 {
            font-size: 0px;
        }

        & p {
            margin: 0;
            font-size: 8px;
        }

        .leader-result-view-score{
            font-size: 10px;
        }
    }
}