.veriy-email-container{
    background-color: white;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    & h2{
        color: var(--main-accent-blue)
    }
}