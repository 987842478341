.loading-component-container {
    min-height: 100vh;
    min-width: 100vw;
    position: fixed;
    left: 0px;
    top: 0px ;
    background-color: var(--dark-bg);
    z-index: 10000;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    & h4 {
        color: white;
        text-align: center;
    }
}

.outer-circ-loading {
    position: relative;
    background-image: linear-gradient(var(--main-accent-blue-hi), var(--main-accent-blue), var(--main-accent-blue),var(--transparent), var(--transparent));
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: 0.87s loading-animation infinite ease-in-out
}

.inner-circ-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--dark-bg);
    width: 90px;
    height: 90px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-outer-circ-loading {
    position: relative;
    background-image: linear-gradient(90deg,rgb(135, 217, 255), var(--main-accent-blue), var(--main-accent-blue),var(--transparent), var(--transparent));
    width: 80px;
    height: 80px;
    border-radius: 50%;
    animation: 0.75s loading-animation infinite ease-in-out
}

.inner-inner-circ-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--dark-bg);
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

@keyframes loading-animation {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}