.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

.my-teammate-modal {
    .modal-dialog {
        min-width: 800px;
    }
}

.team-card-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    z-index: 0;
    gap: 20px;
}

.team-post-card {
    background-color: #f0f0f0;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    padding: 30px;
    flex: 1;
    min-width: calc(33.34vw - 120px);
    max-width: calc(33.34vw - 120px);
    height: 150px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.my-team-teammates-grp {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    width: 80%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.my-team-homebody {
    width: 100%;
    min-height: 100vh;
    background: white;
    padding: 20px;
    box-sizing: border-box;
}


.my-team-panel-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}


.user-head {
    width: 20%;
    display: inline-block;
    padding: auto;
}

.team-top-bar {
    margin-left: 10px;
    float: right;
}

.team-top-bar i:hover {
    cursor: pointer;
}

.team-edit-btn-grp {
    display: flex;
    align-items:flex-end;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin: 20px 0;
    height: 35px;

    & button {
        background-color: rgba(0, 0, 0, 0);
        color: #777;
        border: none;
        border-bottom: 0 solid rgba(0, 0, 0, 0);
        font-size: 18px;
        padding: 0px;
        cursor: pointer;
        white-space: nowrap;
        width: fit-content;
        transition: padding-bottom 0.2s, border-bottom 0.2s, color 0.2s;
    }
    
    & button:hover {
        border-bottom: 1px solid var(--main-accent-blue);
        color: var(--main-accent-blue);
        padding-bottom: 5px;
    }
}

.myteams-modal {
    & input, select {
        box-sizing: border-box;
    }

    .btn-close {
        width: fit-content;
    }

    & button:not([class="btn-close"]) {
        height: 100%;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        padding: 5px 10px;
        max-width: 200px;
        transition: 0.2s background-color;
    }

    & button:last-of-type:not([class="btn-close"]) { 
        background-color: var(--main-accent-blue);
    }

    & button:last-of-type:not([class="btn-close"]):hover {
        background-color: var(--main-accent-blue-hi);
    }
}

.team-head-info {
    border-bottom: 1px solid #ccc;
    .team-name {
      color:var(--main-accent-blue);
    }
}

.teammember-btn {
    background-color: rgba(0, 0, 0, 0);
    white-space: nowrap;
    color: #777;
    border: none;
    border-bottom: 0 solid rgba(0, 0, 0, 0);
    font-size: 18px;
    padding: 0px;
    cursor: pointer;
    transition: height 0.2s, border-bottom 0.2s, color 0.2s;
}
.teammember-btn:hover {
    border-bottom: 1px solid var(--main-accent-blue);
    color: var(--main-accent-blue);
    height: 25px;
}

.teammember-edit-btn-grp {
    display: flex;
    align-items:flex-end;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin: 20px 0;
    height: 35px;


    & button {
        background-color: rgba(0, 0, 0, 0);
        color: #777;
        border: none;
        border-bottom: 0 solid rgba(0, 0, 0, 0);
        font-size: 18px;
        padding: 0px;
        cursor: pointer;
        white-space: nowrap;
        width: fit-content;
        transition: padding-bottom 0.2s, border-bottom 0.2s, color 0.2s;        
    }

    & button:hover {
        border-bottom: 1px solid var(--main-accent-blue);
        color: var(--main-accent-blue);
        padding-bottom: 5px;
    }
}

.team-footer-bar {
    margin-top: 20px;
}

.teammember-card {
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    border-radius: 15px;
    padding: 20px;
    width: 60%;
    min-width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.teammember-card-name-status-row {
    display: flex;
    gap: 0px 50px;
    align-items: center;
    justify-content: flex-end;

    & > div:first-of-type {
        white-space: wrap;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        & > p {
            color: #777;
        }
    }

    & > span:first-of-type {
        margin-left: auto;
    }
}

.responsive-add-member-btn {
    width: 60vw;
    white-space: nowrap;
    position: relative;
    margin-top: 20px;
    display: none;
    left: 50%;
    transform: translateX(-50%);
}

.teammember-info-col {
    width: 40%;
}
.teammember-send-mail-col {
    align-items: flex-start;
}
.teammember-send-mail-btn:hover {
    cursor: pointer;
}

.teammember-card-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    padding: auto;
    margin: auto;
    z-index: 0;
    gap:20px;
}

@media only screen and (max-width: 855px) {
    .my-team-homebody {
        padding: 5px;
    }
    .team-card-container {
        justify-content: center;
    }

    .team-post-card {
        min-width: 300px;
        max-width: 300px;
    }

    .teammember-edit-btn-grp {
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;

        & button:not(:first-of-type) {
            display: none;
        }
    }

    .teammember-card-name-status-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;

        & > span:first-of-type {
            margin-left: 0;
        }
    }

    .responsive-add-member-btn {
        display: block;
    }

    .my-teammate-modal {
        .modal-dialog {
            min-width: 100vw;
        }
    }
}

.myteam-head {
     border-bottom: 1px solid #ccc;

}

.teammember-resend-mail {
    color: var(--main-accent-blue);
}

.teammember-resend-mail:hover {
    cursor: pointer;
}