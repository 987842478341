#contact-container{
    top: 0;
    left: 0;
    z-index: 10000000000;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;
    overflow-y: hidden;
    padding: 20px;
    box-sizing: border-box;
}

#contact-container > div {
    position: relative;
    background-color: white;
    border-radius: 20px;
    padding: 20px 70px 20px 70px;
    width: 500px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
} 

#contact-container > div > h3 {
    align-self: flex-start;
    width: 500px;
    border-bottom: 1px solid var(--main-accent-blue);
}

.contact-field{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.contact-field i{
    align-self: center;
    margin-right: 10px;
}

.field-title {
    font-weight: 700;
}

.contact-field p {
    margin: 15px 0 15px 0;
}

.field-detail {
    margin-left: auto;
    flex: 1;
    text-align: right;
}

@media only screen and (max-width: 855px) {
    #contact-container > div, #contact-container > div > h3 {
        box-sizing: border-box;
        width: 100%;
    }
    #contact-container > div{
        padding: 20px
    }
    .contact-field p {
        margin: 0
    }
    .field-detail{
        text-align: left;
        margin-bottom: 15px;
        flex-basis: 100%;
    }
    .contact-field{
        margin-top: 15px;
    }
}