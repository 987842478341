.rated-leader-homebody {
    width: 100%;
    min-height: 100vh;
    background: white;
    padding: 20px;
    box-sizing: border-box;
}

.rated-leader-card {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    width: 60%;
    min-width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rated-leader-card-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    padding: auto;
    margin: auto;
    margin-top:40px;
    z-index: 0;
    gap:20px;
}

@media only screen and (max-width: 855px) {
    .rated-leader-homebody {
        padding: 5px;
    }
    .rated-leader-card-container {
        justify-content: center;
    }
}

.rated-leader-head {
     border-bottom: 1px solid #ccc;
}