.subscription-link {
    background-color: var(--transparent);
    color: var(--golden);
    transition: background-color 0.2s, color 0.3s ease-out;
    border: none;
    padding: 20px 20px 20px 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-start;

    & span {
        width: 30px
    }

    & p {
        margin: 0;
    }
}

.subscription-link i {
    color: var(--golden);
    transition: color 0.35s ease-out;
}

.subscription-link:hover {
    background-color: var(--golden);
    color: white;
}

.subscription-link:hover i {
    color: white;
}