/* Style for the entire container */
.questionnaire-container {
    width: 100vw;
    background-color: white;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.questionnaire-container>div {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    width: 50%;
}

.questionnaire-container>div:not(:last-of-type) {
    border-bottom: 1px #ccc solid;

}

.questionnaire-container h2 {
    text-align: center;
    color: #333;
    padding-bottom: 10px;
    margin-bottom: 50px;
}

.questionnaire-container h2 span {
    color: var(--main-accent-blue);
}

.questionnaire-container h3 {
    color: #444;
    margin-top: 20px;
}

/* Style for the range and number input */
.questionnaire-container input[type="number"] {
    width: 100%;
    padding: 5px 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.questionnaire-container input[type="number"] {
    width: 60px;
    text-align: center;
}

/* Style for the button */
.questionnaire-container button {
    background-color: var(--main-accent-blue);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    width: 300px;
    transition: 0.2s background-color;
    justify-content: center;
    margin-bottom: 50px;
}

.questionnaire-container button:hover {
    background-color: var(--main-accent-blue-hi);
}

/* styles for custom range */
.questionnaire-container input[type=range] {
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    margin: 10px 0;
    width: 100%;
    overflow: hidden;
    border-radius: 16px;
}

.questionnaire-container input[type=range]:focus {
    outline: none;
}

.questionnaire-container input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    background: #ebebeb;
    border-radius: 16px;
    border: none;

}

.questionnaire-container input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    box-shadow: -407px 0 0 400px var(--main-accent-blue);
    border: 2px solid var(--main-accent-blue);
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.questionnaire-container input[type=range]:focus::-webkit-slider-thumb {
    box-shadow: -407px 0 0 400px var(--main-accent-blue-hi);
}


.questionnaire-container input[type=range]::-moz-range-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    background: #ccc;
    border-radius: 16px;
    border: none;
}

.questionnaire-container input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    box-shadow: -407px 0 0 400px var(--main-accent-blue);
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background-color: #fff;
    cursor: pointer;

}

.questionnaire-container input[type=range]::-ms-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;

}

.questionnaire-container input[type=range]::-ms-fill-lower {
    background: var(--main-accent-blue);
    border: 1px solid var(--main-accent-blue);
    border-radius: 20px;
    box-shadow: 1px 1px 1px #000000;
}

.questionnaire-container input[type=range]::-ms-fill-upper {
    background: var(--main-accent-blue);
    border: 1px solid var(--main-accent-blue);
    border-radius: 16px;
    box-shadow: 1px 1px 1px #000000;
}

.questionnaire-container input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid var(--main-accent-blue);
    height: 15px;
    width: 15px;
    border-radius: 50px;
    background: var(--main-accent-blue);
    cursor: pointer;
}

.questionnaire-container input[type=range]:focus::-ms-fill-lower {
    background: var(--main-accent-blue);
}

.questionnaire-container input[type=range]:focus::-ms-fill-upper {
    background: var(--main-accent-blue);
}

.questionnaire-container input[type=number]:focus {
    outline-width: 0;
    box-shadow: 1px 1px 5px rgb(130, 130, 130);
    background-color: var(--transparent);
}

.spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.question-submitbtn:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
}

.question-loading-body {
    width: 100%;
    margin: auto;
    height: 100vh;
    background: #f7f7f7;
    text-align: center;
    padding-top: 30px;
}

.question-title {
    display: flex;
}

.attr-tooltip {
    position: relative;
    display: inline-block;
}

.attr-tooltip .attr-tooltiptext {
    visibility: hidden;
    width: 500px;
    background-color:#ebebeb;
    color: #444;
    text-align: left;
    border-radius: 6px;
    overflow-wrap: break-word;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 10%;
    left: 50%;
    font-size: 14px;
    margin-left: 20px
}

.attr-tooltip .attr-tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #ebebeb transparent transparent;     
}

.attr-tooltip:hover .attr-tooltiptext {
    visibility: visible;
}

.questionnaire-error-msg {
    color:red;
}