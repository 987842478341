.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-bar h3 {
    font-size: 22px;
    margin: 20px auto 0 0;
}

.del-btn {
    margin-left: 20px;
    color: var(--warning-delete);
    transition: color 0.2s;
}

.del-btn:hover {
    color: var(--warning-delete-hi)
}

.edit-btn {
    margin-left: auto;
    color: #242424;
    transition: color 0.2s;
}

.edit-btn:hover {
    color: #909090
}

.experience-card p {
    margin-bottom: 8px;
}

.experience-card {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(142, 142, 142, 0.577);
    width: calc(33.33% - 56px); /* Allow the card to stretch horizontally */
    min-width: 300px;
}

.experience-card p {
    margin-bottom: 8px;
    line-height: 1.5; /* Improve line spacing for readability */
}

.experience-company {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}
.experience-company h4 {
    color: black;
    font-size: 18px;
}


.experience-location {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    color: #777777;
}
.experience-location p:first-of-type {
    text-align: left;
}
.experience-location p:last-of-type {
    text-align: right;
}

.experience-location p:last-child{
    margin-left: auto;
}

.experience-card > .skill-item-container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 10px;
    /* flex-wrap: nowrap; */
}
.experience-card > .skill-item-container > .skill-item{
    box-shadow: none;
    /* margin-bottom: 10px; */
    white-space: nowrap;
}
.experience-card > .skill-item-container > .skill-item > p {
    margin: 0
}

@media only screen and (max-width: 855px){
    .experience-card{
        box-shadow: none;
        width: 100%
    }
}