.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #fafafa;
}

.login-form {
    background-color: #ffffffcb;
    backdrop-filter: blur(10px);
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    padding: 20px 40px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Spacing for third party */
.third-party-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin: 20px 0;
    padding-bottom: 30px;
    border-bottom: 1px #ccc solid;
}

.login-form .error-message {
    color: rgb(214, 39, 0);
    font-size: 14px;
}

@media only screen and (max-width: 855px) {
    .login-form {
        height: 100%;
        border-radius: 0;
        width: 100%;
    }

    /* Spacing for third party */
    .third-party-container {
        flex-direction: column;
    }

    .login-form-container {
        min-height: 0;
        height: auto;
    }
}