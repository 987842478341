.post-card {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 10px;
    min-height: auto;
    padding: 20px;
    width: 500px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    & > pre {
        margin-top: 20px;
    }

    & > div:first-of-type {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
    }
}

.post-toggle-btn {
    border: 0;
    background-color: var(--transparent);
    color: #777
}

.post-highlighted {
    box-shadow: 2px 2px 10px var(--main-accent-blue-hi)
}

.post-option {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0px;
    top: 50px;
    transform: translateY(calc(-100% - 60px));
    backdrop-filter: blur(50px);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.101);
    opacity: 0;
    transition:  transform 0.2s ease, opacity 0.2s ease;
    z-index: -100;
    white-space: nowrap;

    & button {
        padding: 10px 20px;
        border: 0;
        background-color: rgba(255, 255, 255, 0.441);
        transition: color 0.2s;
        text-align: right;
        color: #777
    }

    & button:first-of-type:hover {
        color: var(--warning-delete-hi)
    }

    & button:nth-of-type(2):hover {
        color: var(--main-accent-blue-hi)
    }
}

.post-card pre {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.post-edit-form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & div {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
}

.like-comment-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    & span {
        font-size: 12px;
        color: #777
    }
}

.like-button,
.comment-button {
    background-color: transparent;
    border: none;
    font-size: 15px;
    cursor: pointer;
    color: #888888;
    transition: color 0.2s;
}

.like-button i,
.comment-button i {
    margin-right: 10px;
}

/* Add hover effect for like and comment buttons */
.like-button:hover, .like-button-active {
    color: rgb(226, 138, 200)
}

.comment-button:hover {
    color: var(--main-accent-blue-hi);
}

.post-file-link > i {
    margin-right: 10px;
}
.post-file-link > p:visited {
    color: #777
}
.post-file-link > p{
    color: #777;
    border-bottom: 1px solid #00000000;
    transition: color 0.2s, border-bottom 0.2s
}
.post-file-link > p:hover{
    color:var(--main-accent-blue);
    border-bottom: 1px solid var(--main-accent-blue);
}

.comment-panel {
    & form {
        display: flex;
        flex-direction: column;

        & button {
            align-self: flex-end;
        }
    }
}

.right-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
}

.post-date {
    font-size: 10px;
    margin-bottom: 0;
    line-height: 10px;
}

.post-comment-container {
    border-top: solid #ccc;
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 70vh;
    overflow-y: auto;

    & pre {
        margin-top: 5px;
    }
}

.post-comment-content-delete-field {
    display: flex;
    align-items: flex-start;

    & button {
        border: 0;
        background-color: var(--transparent);
        font-size: 20px;

        & span {
            color: #ccc;
            transition: color 0.2s
        }
    
        & span:hover {
            color: var(--warning-delete-hi)
        }
    }
}

.comment-text-field {
    display: block;
    width: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    outline: none;
    resize: none;
    border: solid #ccc 1px;
    border-radius: 20px;
    margin: 20px 0;
    height: calc(1em + 30px);
}

#post-comment-text-area {
    transition: height 0.3s ease;
}

.load-more-comments {
    display: flex;
    justify-content: center;

    & * {
        border: none;
        background-color: var(--transparent);
        color: #777;
    }

    & button:hover {
        color: var(--main-accent-blue-hi)
    }
}

@media only screen and (max-width: 855px) {
    .post-card {
        width: 100%;
        box-sizing: border-box;
        border: none;
        border-radius: 0;
        box-shadow: none;
        border-top: 1px #ccc solid;
    }

    .post-highlighted {
        border: 2px solid var(--main-accent-blue-hi);
        border-radius: 10px;
    }
}