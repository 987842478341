.ai-tool-homebody {
  width: 100%;
  min-height: 100vh;
  background: white;
  padding: 20px;
  box-sizing: border-box;
}


.ai-chat-tool-bar {
  display: flex;
  margin: auto;
  margin-top: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.ai-chat-typing-option {
  display: flex;
  gap: 15px;
  width: 70%;
  margin: auto;
}

.ai-chat-body {
  width: 50%;
}

.ai-chat-room {
  position: relative;
  margin-top: 20px;
  height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 10px;
}

.ai-chat-current-date {
  text-align: center;
}

.ai-chat-user-msg {
  border-radius: 8px;
  background-color: antiquewhite;
  width: max-content;
  float: right;
  padding: 5px;
  max-width: 100%;
  word-wrap: break-word;
}

.ai-chat-sys-msg {
  border-radius: 8px;
  border-color: #ddd;
  background-color: #ddd;
  width: max-content;
  padding: 5px;
  word-wrap: break-word;
  max-width: 100%;
}

.ai-chat-msg-container-left {
  position: relative;
  margin: 10px;
}

.ai-chat-msg-container-left::before {
  content: '';
  position: absolute;
  top: 10%;
  left: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 10px;
  border-color: #ddd transparent transparent transparent;
}

.ai-chat-msg-container-right {
  position: relative;
  margin: 10px;
  float: right;
}

.ai-chat-msg-container-right::before {
  content: '';
  position: absolute;
  top: 10%;
  right: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 10px;
  border-color: transparent transparent transparent antiquewhite;
}

.ai-tool-tabs {
  display: flex;
  width: 100%;
  align-content: space-between;
  gap: 20px;
}


.ai-tool-tabs button {
  border-radius: 5px 5px 0 0;
  border: 1px solid #e1e1e1;
}

.ai-tool-btn-active {
  background-color: var(--main-accent-blue);
  color: #ffffff;
  border: 1px solid #e1e1e1;
}

@media only screen and (max-width: 855px) {

  .ai-chat-body {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    position: relative;
    justify-content: center;

  }

  .ai-chat-typing-option {
    display: flex;
    gap: 5px;
    width: 100%;
    margin: auto;
  }  

}

.ai-chat-setting {
  display: flex;
  flex-direction: column;
}

.ai-chat-btm-msg {
  position:absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.ai-chat-input-btm-button {
  position: absolute;
  bottom:15px;
  right: 5px;
  border: none;
  background-color: #fff;
}

.ai-chat-input-btm-button:hover {
  background-color: var(--main-accent-blue);
  border-radius: 40%;
  color: #fff;
  border: none;
}

.ai-chat-input {
  position: relative;
  width: 90%;
}

.ai-chat-red-phone {
  height: fit-content;
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
  max-width: 200px;
  transition: 0.2s background-color, max-width 0.4s ease, opacity 0.2s ease, padding 0.4s ease, letter-spacing 0.4s ease;
  white-space: nowrap;
  width: fit-content;

  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
}

.ai-chat-green-phone {
  height: fit-content;
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
  max-width: 200px;
  transition: 0.2s background-color, max-width 0.4s ease, opacity 0.2s ease, padding 0.4s ease, letter-spacing 0.4s ease;
  white-space: nowrap;
  width: fit-content;

  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
}