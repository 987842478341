/* Theme button */
.theme-button {
    height: fit-content;
    background-color: var(--main-accent-blue);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 10px;
    max-width: 200px;
    transition: 0.2s background-color, max-width 0.4s ease, opacity 0.2s ease, padding 0.4s ease, letter-spacing 0.4s ease;
    white-space: nowrap;
    width: fit-content;

    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
}

.theme-button:hover{
    background-color: var(--main-accent-blue-hi);
}

/* Forms Styling */
.edit-form-group {
    width: 100%;
    margin-bottom: 15px;
    align-items: center;
}

.edit-form-group label {
    font-weight: bold;
}

.edit-form-group input[type="email"],
.edit-form-group input[type="password"],
.edit-form-group input[type="text"],
.edit-form-group input[type="file"],
.edit-form-group input[type="textbox"],
.edit-form-group input[type="number"],
.edit-form-group input[type="date"],
.edit-form-group input[type="month"],
.edit-form-group input[type="tel"],
.edit-form-group textarea,
.edit-form-group select {
    font-size: 16px;
    width: 100%;
    padding: 10px 10px 10px 10px;
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
    display: block;
    transition: box-shadow 0.2s;
    box-sizing: border-box;
    background-color: white;
}

.edit-form-group select {
    width: 40ch;
    max-width: 100%;
}

.edit-form-group input:disabled {
    background-color: #f6f6f6;
}

/* Form Link */
.edit-form-group p {
    margin-top: 10px;
}

.edit-form-group a {
    color: #007bff;
    text-decoration: none;
}

.edit-form-group a:hover {
    text-decoration: underline;
}

.edit-form-group input:not([class="degree-selector__input"]):focus,
.edit-form-group textarea:focus,
.edit-form-group select:focus {
    outline-width: 0;
    box-shadow: 1px 1px 5px rgb(130, 130, 130);
    background-color: white;
    border: 0;
}

.edit-form-group input[type="checkbox"] {
    outline: 0;
}

.edit-form-group input[type="checkbox"]:focus {
    background-color: #ddd;
}

.edit-form-group input[type="checkbox"]:checked {
    background-color: var(--main-accent-blue-hi);
    border: 0;
}

.edit-form-group .confirm { 
    border: none;
    background-color: var(--main-accent-blue);
    transition: background-color 0.2s ease-in
}

.edit-form-group .confirm:hover {
    background-color: var(--main-accent-blue-hi);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    box-sizing: border-box;

    & input, select {
        box-sizing: border-box;
    }

    .form-label {
        margin: 0;
    }

    .btn-close {
        width: fit-content;
        outline: none;
        box-shadow: none;
    }

    .modal-content {
        box-sizing: border-box;
        padding: 20px 30px;
    }

    .modal-footer {
        padding-top: 20px;
    }

    .modal-header {
        margin-bottom: 20px;

        & h3 {
            color: var(--main-accent-blue)
        }
    }

    & button:not([class="btn-close"]), .btn {
        height: 100%;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        padding: 5px 10px;
        max-width: 200px;
        transition: 0.2s background-color;
    }

    .confirm { 
        background-color: var(--main-accent-blue);
        transition: background-color 0.2s ease-in
    }

    .confirm:hover {
        background-color: var(--main-accent-blue-hi);
    }
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 99999;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #888;
    cursor: pointer;
}

.premium-user-pfp {
    border: 1.5px var(--golden) solid;
    box-shadow: 0 0 3px var(--golden);
}

@media only screen and (max-width: 855px) {
    .modal {
        .modal-footer {
            justify-content: center;
        }

        .btn {
            height: 100%;
        }

        .modal-dialog {
            margin: 0 auto 0 auto;
        }
    }
}